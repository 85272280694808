export class ResultWrapper<T> {
  status: number;
  message: string;
  result: T;

  get isStatusError(): boolean {
    return this.status > 9999;
  }

  deserialize(input: any) {
    this.status = input.status;
    this.message = input.message;
    // this.result = <T>new type().deserialize(input.result);
    return this;
  }
}
