import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {tokenNotExpired} from 'angular2-jwt';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {forEach} from '@angular/router/src/utils/collection';
import {retry} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public storageKey = 'WhatsMR-Jwt-Token';

  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private router: Router, private httpClient: HttpClient) {
  }


  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
    this.cachedRequests.forEach(cr => {

      cr.headers.set('authorization', `Bearer ` + this.getToken());
      this.httpClient.request(cr);
    });

  }


  setToken(token: string) {
    localStorage.setItem(this.storageKey, token);
  }

  getToken() {
    return localStorage.getItem(this.storageKey);
  }

  isLoggedIn() {
    return tokenNotExpired(this.storageKey);
  }

  logout() {
    localStorage.removeItem(this.storageKey);
    this.router.navigate(['/login']);
  }


}
