import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ErrorComponent} from './error/error.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {AuthService} from './auth/auth.service';
import {AuthGuard} from './auth/auth.guard';
import {TokenInterceptor} from './auth/token.interceptor';
import {JwtInterceptor} from './auth/jwt.interceptor';



@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
  ],
  providers: [    {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  },
    AuthService,
    AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
