import 'rxjs/add/operator/do';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Injectable, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {StringResult} from '../shared/models/string-result';
import {ErrorComponent} from '../error/error.component';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  @ViewChild(ErrorComponent) errorDisplay?: ErrorComponent;

  constructor(public auth: AuthService, public router: Router, public api: ApiService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 && !request.url.includes('refreshtoken')) {

          this.auth.collectFailedRequest(request);

          // ask for new token by sending the old to the server
          this.refreshToken();

          this.auth.retryFailedRequests();

        } else {
          this.router.navigate(['/login']);
        }
      }
    });
  }

  refreshToken() {

    this.api.post<StringResult>('authentication/refreshtoken', {'token': this.auth.getToken()}, StringResult)
      .subscribe(result => {
          if (result.isStatusError) {
            console.log(`We handled the result error with status ${result.status} message ${result.message}`);
            this.errorDisplay.errorMessage = result.message;
            return;
          }

          if (result.result.content != null) {
            this.auth.setToken(result.result.content);


          } else {
            // if token not working then
            this.router.navigate(['/login']);
          }
        },
        error => {
          console.log(error);
          this.errorDisplay.errorMessage = error.error.Message;
        });


  }


}
