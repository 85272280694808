import {Component, OnInit, ViewChild} from '@angular/core';
import {WmrAccount} from './shared/models/account.model';
import {ErrorComponent} from './error/error.component';
import {ApiService} from './api.service';
import {AuthService} from './auth/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild(ErrorComponent) errorDisplay?: ErrorComponent;

    title = 'WhatsMROrderClientAngular';


    constructor(public api: ApiService, private auth: AuthService) {
    }

    wmrAccount?: WmrAccount;


    ngOnInit() {

      /* // this.app.setTitle('Account Details');

        this.api.get<WmrAccount>('account/GetAccount', WmrAccount)
            .subscribe(data => {
                if (data.isStatusError) {
                    this.errorDisplay.errorMessage = data.message;
                    return;
                }
                this.wmrAccount = data.result;
            });*/
    }
}

