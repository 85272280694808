import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Observable, throwError, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {AuthService} from './auth/auth.service';

import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {ResultWrapper} from './shared/models/result-wrapper';
import {Deserializable} from './shared/deserializable';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  // handle response for single objects
  private static handleResponse<T extends Deserializable>(response: ResultWrapper<T>, type: (new () => T)): Observable<ResultWrapper<T>> {
    const resultWrapper = new ResultWrapper<T>().deserialize(response);
    resultWrapper.result = new type().deserialize(response.result);
    return of(resultWrapper);
  }

  // handle response for list objects
  private static handleListResponse<T extends Deserializable>(response: ResultWrapper<T[]>,
                                                              type: (new () => T)): Observable<ResultWrapper<T[]>> {
    const resultWrapper = new ResultWrapper<T[]>().deserialize(response);
    resultWrapper.result = response.result.map(r => new type().deserialize(r));
    return of(resultWrapper);
  }

  get<T extends Deserializable>(url: string, type: (new () => T)): Observable<ResultWrapper<T>> {
    const headers = this.getHeaders();
    return this.http.get<ResultWrapper<T>>(this.getUrl(url), {headers: headers}).pipe(
      switchMap(r => ApiService.handleResponse<T>(r, type)),
      catchError(this.onRequestError)
    );
  }

  getReturnList<T extends Deserializable>(url: string, type: (new () => T)): Observable<ResultWrapper<T[]>> {
    const headers = this.getHeaders();
    return this.http.get<ResultWrapper<T[]>>(this.getUrl(url), {headers: headers}).pipe(
      switchMap(res => ApiService.handleListResponse<T>(res, type))
    );
  }


  post<T extends Deserializable>(url: string, body: Object, type: (new () => T)): Observable<ResultWrapper<T>> {
    const headers = this.getHeaders();
    return this.http.post<ResultWrapper<T>>(this.getUrl(url), body, {headers: headers}).pipe(
      switchMap(r => ApiService.handleResponse<T>(r, type)),
      catchError(this.onRequestError)
    );
  }


  put<T extends Deserializable>(url: string, body: Object, type: (new () => T)): Observable<ResultWrapper<T>> {
    const headers = this.getHeaders();
    return this.http.put<ResultWrapper<T>>(this.getUrl(url), body, {headers: headers}).pipe(
      switchMap(r => ApiService.handleResponse<T>(r, type)),
      catchError(this.onRequestError)
    );
  }

  delete<T extends Deserializable>(url: string, type: (new () => T)): Observable<ResultWrapper<T>> {
    const headers = this.getHeaders();
    return this.http.delete<ResultWrapper<T>>(this.getUrl(url), {headers: headers}).pipe(
      switchMap(r => ApiService.handleResponse<T>(r, type)),
      catchError(this.onRequestError)
    );
  }

  getUrl(url: string): string {
    return `${this.baseUrl}/${url}`;
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
    //  'Content-Type': 'application/json', 'Authorization': `Bearer ${this.auth.getToken()}`

      'Content-Type': 'application/json', 'Authorization': `Bearer ${this.auth.getToken()}`
    });
  }

  getHeaders1(): HttpHeaders {
    return new HttpHeaders({'Content-Type': 'application/json'});
  }

  onRequestError(res: HttpErrorResponse) {

    console.log(res);
    return throwError(res);
  }

}
